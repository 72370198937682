import React from "react"
import { Link } from "gatsby"
import ImageTwo from "../components/image2"
import Layout from "../components/layout"
import SEO from "../components/seo"


const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <section>
    <div className="content">
    <h1>Where optimization ends, SEO BOSS action starts</h1>
    <hr></hr>
    <p>Contact us</p>
      <ul>
      <li><a href="https://telegram.me/seoboss_alex"><img class="ikonice" src="https://img.icons8.com/color/48/000000/telegram-app.png"></img></a></li>
      <li><a href="skype:aleksndar.supic?call"><img class="ikonice" src="https://img.icons8.com/color/48/000000/skype.png"></img></a></li>
      <li><a href="tel:381641352496"><img class="ikonice" src="https://img.icons8.com/ultraviolet/40/000000/phone.png"></img></a></li>
      </ul>
      <div class="container">  
  <form id="contact"  action="https://formspree.io/xpzzrzgg" method="POST">
      <input placeholder="Your Email Address" type="text" name="_replyto" tabindex="2" required></input>
    <fieldset>
      <textarea placeholder="Type your message here...." textarea name="message" tabindex="5" required></textarea>
    </fieldset>
    <fieldset>
      <button type="submit" id="contact-submit" data-submit="...Sending">Send</button>
    </fieldset>
  </form>
</div>
      <div class="imgpos" style={{ maxWidth: `300px` }}>
    <ImageTwo /> </div>
    </div>
    </section>

  </Layout>
)

export default IndexPage
